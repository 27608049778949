import React from 'react';
import { useNavigate } from 'react-router-dom';

import { baseRoute } from 'utils/constants';

import { SessionTimeoutScreen } from './screens/SessionTimeoutScreen';

export const SessionTimeoutPage = () => {
    const navigate = useNavigate();
    const redirectToWelcomeConsentPage = () => {
        navigate(`/${baseRoute}/`);
    };

    const redirectToWalmartRewards = () => {
        navigate(`/`);
    };

    return (
        <SessionTimeoutScreen
            redirectToWalmartRewards={redirectToWalmartRewards}
            redirectToWelcomeConsent={redirectToWelcomeConsentPage}
        />
    );
};
