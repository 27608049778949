import { ButtonProps } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components/Button';

import styles from './TextButton.module.scss';

export interface ICustomButtonProps extends ButtonProps {
    className?: string;
    target?: string;
}
export const TextButton: React.FC<ICustomButtonProps> = ({ children, className = '', ...rest }): React.ReactElement => (
    <Button {...rest} appearance="text" border className={`${styles.button} ${className}`} disableRipple size="medium">
        {children}
    </Button>
);
