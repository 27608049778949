import React from 'react';
import { SessionManagerProvider } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';

import InactiveSessionModal from 'components/InactiveSessionModal';
import { onResetEvent } from 'store/redux/actions';
import { useAppDispatch } from 'store/redux/hooks';
import { baseRoute, ERoutes } from 'utils/constants';

import styles from './Session.module.scss';

export const SessionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();
    return (
        <SessionManagerProvider
            activitateSessionTimeout={false}
            className={styles.sessionContainer}
            onFinishTimeout={() => {
                dispatch(onResetEvent(`/${baseRoute}/${ERoutes.SESSION_TIMEOUT}`));
            }}
            text={InactiveSessionModal}
        >
            {children}
        </SessionManagerProvider>
    );
};
