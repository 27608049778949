import React from 'react';
import { useIntl } from 'react-intl';

import { EOfferCode } from 'utils/constants';

import { ComparisonChartSection } from './sections/ComparisonChart/ComparisonChart';
import { EasyToApplySection } from './sections/EasyToApply/EasyToApply';
import { HeroBannerSection } from './sections/HeroBanner/HeroBanner';
import { LegalSection } from './sections/Legal/Legal';
import { MediaCopySection } from './sections/MediaCopy/MediaCopy';
import { RatesCalloutSection } from './sections/RatesCallout/RatesCallout';
import { RewardsCalculatorSection } from './sections/RewardsCalculator/RewardsCalculator';
import { RewardsTickerSection } from './sections/RewardsTicker/RewardsTicker';
import { RewardsToCashSection } from './sections/RewardsToCash/RewardsToCash';

import styles from './SplashScreen.module.scss';

interface ISplashScreenProps {
    applyNow: () => void;
    offerCode: EOfferCode;
    isAssociateChannel: boolean;
}
export const SplashScreen: React.FC<ISplashScreenProps> = ({
    applyNow,
    isAssociateChannel,
    offerCode,
}): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    return (
        <div className={styles.container}>
            <HeroBannerSection applyNow={applyNow} isAssociateChannel={isAssociateChannel} locale={locale} />
            <EasyToApplySection applyNow={applyNow} isAssociateChannel={isAssociateChannel} offerCode={offerCode} />
            <RewardsToCashSection locale={locale} />
            <ComparisonChartSection applyNow={applyNow} isAssociateChannel={isAssociateChannel} locale={locale} />
            <RewardsCalculatorSection applyNow={applyNow} isAssociateChannel={isAssociateChannel} />
            <MediaCopySection />
            <RatesCalloutSection />
            <RewardsTickerSection applyNow={applyNow} />
            <LegalSection isAssociateChannel={isAssociateChannel} offerCode={offerCode} />
        </div>
    );
};
