import React from 'react';
import { Box } from '@fairstone/ui/core';
import { Typography } from '@fairstone/ui/core/components';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { TextButton } from 'components/Buttons/walmart/TextButton';

import styles from './SessionTimeoutScreen.module.scss';

interface ISessionTimeoutScreenProps {
    redirectToWelcomeConsent: () => void;
    redirectToWalmartRewards: () => void;
}

export const SessionTimeoutScreen = ({
    redirectToWalmartRewards,
    redirectToWelcomeConsent,
}: ISessionTimeoutScreenProps) => (
    <Box className={styles.sessionTimeoutPageContainer}>
        <Box className={styles.contents}>
            <Typography className={styles.title}>{t('pages.sessionTimeout.title')}</Typography>
            <Typography className={styles.message}>{t('pages.sessionTimeout.message')}</Typography>
        </Box>
        <Box className={styles.buttons}>
            <PrimaryButton fullWidth={false} onClick={redirectToWelcomeConsent}>
                {t('pages.sessionTimeout.restartAppButton')}
            </PrimaryButton>
            <TextButton onClick={redirectToWalmartRewards}>
                {t('pages.sessionTimeout.redirectToWalmartRewardsbutton')}
            </TextButton>
        </Box>
    </Box>
);
