import React from 'react';
import { useIntl } from 'react-intl';
import { Accordion, AccordionDetails, AccordionSummary } from '@fairstone/ui/core';
import { Link } from '@fairstone/ui/core/components/Link';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t, translate } from '@fairstone/ui/core/utils/translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { LegalLinkList } from 'components/LegalLinkList/LegalLinkList';
import { EOfferCode, ERequestChannel } from 'utils/constants';
import { documentPath } from 'utils/path/path-utils';

import styles from './Legal.module.scss';

interface ILegalSectionProps {
    isAssociateChannel: boolean;
    offerCode: EOfferCode;
}

export const LegalSection = ({
    isAssociateChannel,
    offerCode = EOfferCode.DEFAULT,
}: ILegalSectionProps): React.ReactElement => {
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    const channelOffer = isAssociateChannel ? ERequestChannel.Associate : 'OTHERS';
    return (
        <div className={styles.container}>
            <Accordion className={styles.accordion}>
                <AccordionSummary
                    aria-controls="panel-content"
                    className={styles.header}
                    expandIcon={<ExpandMoreIcon className={styles.icon} data-testid="expand-btn" />}
                    id="panel-header"
                >
                    <Typography className={styles.panelTitle} component="h2">
                        {t('pages.splash.legal.title')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails data-testid="accordion-detail">
                    <div className={styles.content}>
                        {Array.from({ length: 10 }, (_, index) => (
                            <Typography key={index} variant="body3">
                                {t(`pages.splash.legal.paragraph${index + 1}`, {
                                    bonusOffer: t(`pages.splash.legal.bonusOffer.${channelOffer}.${offerCode}`, {
                                        breakElement: <div className={styles.breakElement} />,
                                        link: (
                                            <Link
                                                className={styles.agreementLink}
                                                href={documentPath(
                                                    translate('pages.splash.legal.bonusOffer.link.fileName'),
                                                    locale
                                                )}
                                                size="small"
                                                target="_blank"
                                            >
                                                {t('pages.splash.legal.bonusOffer.link.title')}
                                            </Link>
                                        ),
                                        MC: <sup>MC</sup>,
                                        MD: <sup>MD</sup>,
                                        refCrossOne: (
                                            <Typography className={styles.refCross} variant="body3">
                                                †
                                            </Typography>
                                        ),
                                        refCrossThree: (
                                            <Typography className={styles.refCross} variant="body3">
                                                †††
                                            </Typography>
                                        ),
                                        refCrossTwo: (
                                            <Typography className={styles.refCross} variant="body3">
                                                ††
                                            </Typography>
                                        ),
                                    }),
                                    ER: <sup>er</sup>,
                                    link: (
                                        <Link
                                            className={styles.agreementLink}
                                            href={documentPath(
                                                translate(`pages.splash.legal.link${index + 1}.fileName`),
                                                locale
                                            )}
                                            size="small"
                                            target="_blank"
                                        >
                                            {t(`pages.splash.legal.link${index + 1}.title`)}
                                        </Link>
                                    ),
                                    MC: <sup>MC</sup>,
                                    MD: <sup>MD</sup>,
                                    percentage: (
                                        <span>{t(`pages.splash.legal.percentage${index + 1}.${channelOffer}`)}</span>
                                    ),
                                    SLASH: <sup>/</sup>,
                                })}
                            </Typography>
                        ))}
                    </div>

                    <LegalLinkList titleId="components.legal.linkList.title" />
                </AccordionDetails>
            </Accordion>
        </div>
    );
};
