import React from 'react';
import {
    ISessionManagerProvider,
    useSessionManager,
} from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { Box, Typography } from '@fairstone/ui/core';
import { t } from '@fairstone/ui/core/utils/translate';

import styles from './InactiveSessionModal.module.scss';

export const SessionPopUpScreen: React.FC = () => {
    const { seconds } = useSessionManager();
    return (
        <Box className={styles.sessionPopUp}>
            <Typography className={styles.title}>{t('components.sessionTimeout.modal.title', { seconds })}</Typography>
            <Typography className={styles.message}>{t('components.sessionTimeout.modal.message')}</Typography>
        </Box>
    );
};

export const InactiveSessionModal: ISessionManagerProvider['text'] = {
    action: t('components.sessionTimeout.modal.actionButton'),
    message: <SessionPopUpScreen />,
};
