import { useEffect } from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, redirect, Route, useLocation } from 'react-router-dom';
import { useNavigateLastSavePoint } from 'hooks/navigate/useNavigateLastSavePoint/useNavigateLastSavePoint';

import { LargeLayout } from 'layouts/walmart/large/LargeLayout';
import { SmallLayout } from 'layouts/walmart/small/SmallLayout';
import ErrorPage from 'pages/Error';
import AdditionalCardHolderPage from 'pages/walmart/AddOns/AdditionalCardHolder';
import AdditionalCardHolderDetailsPage from 'pages/walmart/AddOns/AdditionalCardHolderDetails';
import InsurancePage from 'pages/walmart/AddOns/Insurance';
import PaperlessPage from 'pages/walmart/AddOns/Paperless';
import CancelApplicationPage from 'pages/walmart/CancelApplication';
import ApprovedPage from 'pages/walmart/Confirmation/Approved';
import DeclinedPage from 'pages/walmart/Confirmation/Declined';
import FinancingPage from 'pages/walmart/Confirmation/Financing';
import PendingApprovalPage from 'pages/walmart/Confirmation/PendingApproval';
import SetUpPage from 'pages/walmart/Confirmation/SetUp';
import SetUpCompletePage from 'pages/walmart/Confirmation/SetUpComplete';
import IncomePage from 'pages/walmart/Employment/Income';
import JobTitlePage from 'pages/walmart/Employment/JobTitle';
import StatusPage from 'pages/walmart/Employment/Status';
import LivingArrangementPage from 'pages/walmart/Household/LivingArrangement';
import PhoneNumberPage from 'pages/walmart/Household/PhoneNumber';
import RentMortgagePage from 'pages/walmart/Household/RentMortgage';
import IDSelectionPage from 'pages/walmart/IDSelection';
import InvalidAgePage from 'pages/walmart/InvalidAge';
import AddressPage from 'pages/walmart/Manual/Address';
import BirthdayPage from 'pages/walmart/Manual/Birthday';
import NamePage from 'pages/walmart/Manual/Name';
import UnableToVerifyPage from 'pages/walmart/Manual/UnableToVerify';
import NotFoundPage from 'pages/walmart/NotFound';
import OnfidoConfirmationPage from 'pages/walmart/Onfido/Confirmation';
import OnfidoVerifyPage from 'pages/walmart/Onfido/Verify';
import OtpAnswerCustomChallengePage from 'pages/walmart/Otp/Confirm';
import OtpFailedPage from 'pages/walmart/Otp/Failed';
import PrivacyPage from 'pages/walmart/Privacy';
import ReviewPage from 'pages/walmart/Review';
import ContinuePage from 'pages/walmart/SaveAndContinue/Continue';
import ResumePage from 'pages/walmart/SaveAndContinue/Resume';
import UnsubscribePage from 'pages/walmart/SaveAndContinue/Unsubscribe';
import SessionTimeoutPage from 'pages/walmart/SessionTimeout';
import SplashPage from 'pages/walmart/Splash';
import SubmitApplicationPage from 'pages/walmart/SubmitApplication';
import WelcomeConsentPage from 'pages/walmart/WelcomeConsent';
import WhereCanWeReachYouPage from 'pages/walmart/WhereCanWeReachYou';
import authService from 'services/auth/auth';
import { pushRouteChangeDataLayer } from 'services/gtm';
import { useAppDispatch, useAppSelector } from 'store/redux/hooks';
import { setHasSubmittedApplication, userState } from 'store/redux/modules/user';
import { CARDHOLDER_AGREEMENT_ROUTES, ERoutes, EVerificationPaths, PROMO_ROUTES } from 'utils/constants';
import { documentPath } from 'utils/path/path-utils';
import { SEOPaths } from 'utils/seo/constants';

import RouteAppLayout from './RouteAppLayout';
import SEOLayout from './SEOLayout';

const deleteLocalStorage = () => {
    authService.clearCognito();
    window.localStorage.removeItem('saved_qp');
};

const SubmittedPages = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(setHasSubmittedApplication());
    }, []);
    return <Outlet />;
};

const PageChange = () => {
    const location = useLocation();

    useEffect(() => {
        pushRouteChangeDataLayer();
    }, [location]);
    return <Outlet />;
};

const FlowVerification = ({
    checkHasSubmittedApplication = false,
    checkHasVerification = false,
    checkUnAuthId = false,
}: {
    checkUnAuthId?: boolean;
    checkHasVerification?: boolean;
    checkHasSubmittedApplication?: boolean;
}) => {
    const user = useAppSelector(userState);
    const { onNavigateLastSavePoint } = useNavigateLastSavePoint();
    const hasVerification = user.manualVerificationResult !== null || user.digitalVerificationSent;
    const { hasSubmittedApplication } = user;

    useEffect(() => {
        if (
            (checkUnAuthId && !user.unauthId) ||
            (checkHasVerification && !hasVerification) ||
            (checkHasSubmittedApplication && hasSubmittedApplication)
        ) {
            onNavigateLastSavePoint();
        }
    }, [user, hasVerification, hasSubmittedApplication]);

    return <Outlet />;
};

const elements = (
    <Route element={<PageChange />}>
        <Route
            element={
                <RouteAppLayout>
                    <Outlet />
                </RouteAppLayout>
            }
        >
            <Route
                element={
                    <SEOLayout path={SEOPaths.SPLASH}>
                        <LargeLayout>
                            <Outlet />
                        </LargeLayout>
                    </SEOLayout>
                }
            >
                <Route
                    element={<SplashPage />}
                    index
                    key="splash"
                    loader={() => {
                        deleteLocalStorage();
                        return null;
                    }}
                />
                {['en/:slug', 'fr/:slug', 'fr', 'en', ':slug'].map((path, index) => (
                    <Route
                        element={<SplashPage />}
                        key={`splash-${index}`}
                        loader={() => {
                            deleteLocalStorage();
                            return null;
                        }}
                        path={path}
                    />
                ))}
            </Route>
            <Route
                element={
                    <SEOLayout path={SEOPaths.APPLY}>
                        <Outlet />
                    </SEOLayout>
                }
                path="/apply"
            >
                {/* Application Confirmation section */}
                <Route element={<SubmittedPages />}>
                    <Route element={<ApprovedPage />} path={ERoutes.CONFIRMATION_APPROVED} />
                    <Route element={<FinancingPage />} path={ERoutes.CONFIRMATION_FINANCING} />
                    <Route element={<PendingApprovalPage />} path={ERoutes.CONFIRMATION_PENDING} />
                    <Route element={<SetUpPage />} path={ERoutes.CONFIRMATION_APPROVED_SETUP} />
                    <Route element={<DeclinedPage />} path={ERoutes.CONFIRMATION_DECLINED} />
                </Route>

                <Route
                    element={
                        <LargeLayout>
                            <Outlet />
                        </LargeLayout>
                    }
                >
                    <Route element={<WelcomeConsentPage />} index />
                </Route>
                <Route
                    element={
                        <SmallLayout>
                            <Outlet />
                        </SmallLayout>
                    }
                >
                    <Route element={<FlowVerification checkUnAuthId={true} />}>
                        <Route element={<WhereCanWeReachYouPage />} path={ERoutes.WHERE_CAN_WE_REACH_YOU} />
                        <Route element={<IDSelectionPage />} path={ERoutes.APP_START} />
                        <Route element={<InvalidAgePage />} path={ERoutes.INVALID_AGE} />
                        <Route element={<CancelApplicationPage />} path={ERoutes.CANCEL_APPLICATION} />

                        {/* Digital section */}
                        <Route element={<OnfidoVerifyPage />} path={ERoutes.DIGITAL_ONFIDO} />
                        <Route element={<OnfidoConfirmationPage />} path={ERoutes.DIGITAL_CONFIRMATION} />
                        <Route
                            element={<UnableToVerifyPage path={EVerificationPaths.ONFIDO} />}
                            path={ERoutes.DIGITAL_UNABLE_TO_VALIDATE}
                        />
                        {/* Manual section */}
                        <Route element={<NamePage />} path={ERoutes.MANUAL_NAME} />
                        <Route element={<BirthdayPage />} path={ERoutes.MANUAL_BIRTHDATE} />
                        <Route element={<AddressPage />} path={ERoutes.MANUAL_ADDRESS} />
                        <Route
                            element={<UnableToVerifyPage path={EVerificationPaths.MANUAL} />}
                            path={ERoutes.MANUAL_UNABLE_TO_VALIDATE}
                        />

                        <Route element={<FlowVerification checkHasVerification={true} />}>
                            {/* Household section */}
                            <Route element={<PhoneNumberPage />} path={ERoutes.PHONE_NUMBER} />
                            <Route element={<LivingArrangementPage />} path={ERoutes.LIVING_ARRANGEMENT} />
                            <Route element={<RentMortgagePage />} path={ERoutes.RENT_OR_MORTGAGE} />

                            {/* Employment section */}
                            <Route element={<StatusPage />} path={ERoutes.EMPLOYMENT_STATUS} />
                            <Route element={<IncomePage />} path={ERoutes.INCOME} />
                            <Route element={<JobTitlePage />} path={ERoutes.JOB_TITLE} />

                            {/* AddOns section */}
                            <Route element={<PaperlessPage />} path={ERoutes.PAPERLESS} />

                            <Route element={<ReviewPage />} path={ERoutes.REVIEW} />

                            <Route element={<PrivacyPage />} path={ERoutes.PRIVACY} />

                            {/* Submit application */}
                            <Route element={<FlowVerification checkHasSubmittedApplication={true} />}>
                                <Route element={<SubmitApplicationPage />} path={ERoutes.SUBMIT_APPLICATION} />
                            </Route>
                        </Route>
                    </Route>

                    {/* OTP section */}
                    <Route element={<OtpAnswerCustomChallengePage />} path={ERoutes.CONFIRM_OTP} />
                    <Route element={<OtpFailedPage />} path={ERoutes.OTP_FAILED} />
                    {/* Save & Continue */}
                    <Route element={<ContinuePage />} path={ERoutes.SAC_CONTINUE} />
                    <Route element={<ResumePage />} path={ERoutes.SAC_RESUME} />
                    <Route element={<UnsubscribePage />} path={ERoutes.SAC_UNSUBSCRIBE} />
                    {/* Confirmation section */}
                    <Route element={<SubmittedPages />}>
                        <Route element={<SetUpCompletePage />} path={ERoutes.CONFIRMATION_SETUP_COMPLETE} />
                    </Route>
                    {/* Session Timeout */}
                    <Route element={<SessionTimeoutPage />} path={ERoutes.SESSION_TIMEOUT} />
                </Route>
                {/* AddOns section */}
                <Route element={<FlowVerification checkHasVerification={true} checkUnAuthId={true} />}>
                    <Route element={<AdditionalCardHolderPage />} path={ERoutes.ADDITIONAL_CARD_HOLDER} />
                    <Route
                        element={
                            <SmallLayout>
                                <Outlet />
                            </SmallLayout>
                        }
                    >
                        <Route
                            element={<AdditionalCardHolderDetailsPage />}
                            path={ERoutes.ADDITIONAL_CARD_HOLDER_DETAILS}
                        />
                    </Route>
                    <Route element={<InsurancePage />} path={ERoutes.INSURANCE} />
                </Route>
            </Route>
            <Route
                element={
                    <SmallLayout>
                        <Outlet />
                    </SmallLayout>
                }
            >
                <Route
                    element={<ErrorPage />}
                    loader={() => {
                        deleteLocalStorage();
                        return null;
                    }}
                    path="/error"
                />
            </Route>
            {/* PROMO Routes */}
            {PROMO_ROUTES.map(({ from, to }) => (
                <Route key={`promo-routes-${from}`} loader={() => redirect(to)} path={from} />
            ))}
            {/* Card holder agreement Routes */}
            {CARDHOLDER_AGREEMENT_ROUTES.map(({ from, locale, to }) => (
                <Route
                    key={`vanity-routes-${from}`}
                    loader={() => (window.location.href = documentPath(to, locale))}
                    path={from}
                />
            ))}
            <Route
                element={
                    <SEOLayout path={SEOPaths.NOT_FOUND}>
                        <Outlet />
                    </SEOLayout>
                }
            >
                <Route element={<NotFoundPage />} path="/404" />
                <Route element={<NotFoundPage />} path="*" />
            </Route>
        </Route>
    </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(elements));
