import { useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { IOption } from 'components/ControlledAutoComplete/ControlledAutoComplete';
import { industries as industryList } from 'config/industries';
import { jobs as jobList } from 'config/jobs';

const getFirstCharNormalizedCode = (label: string): number =>
    label && label.length
        ? label[0]
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .charCodeAt(0)
        : 0;

const buildOptions = (list: IOption[], prefix: string, intl: IntlShape) =>
    list
        ?.reduce((acc: IOption[], item: IOption) => {
            const label: string = intl.formatMessage({ id: `${prefix}${item.label}` });
            if (label) {
                acc.push({
                    ...item,
                    label,
                });
            }
            return acc;
        }, [])
        .sort((a: IOption, b: IOption) => getFirstCharNormalizedCode(a?.label) - getFirstCharNormalizedCode(b?.label));

const useJobList = (): {
    industries: IOption[];
    jobs: IOption[];
} => {
    const intl = useIntl();
    const [industries, setIndustries] = useState<IOption[]>([]);
    const [jobs, setJobs] = useState<IOption[]>([]);

    useEffect(() => {
        setIndustries(buildOptions(industryList, 'components.jobPicker.industryList.', intl));
        setJobs(buildOptions(jobList, 'components.jobPicker.jobList.', intl));
    }, [intl.locale]);

    return {
        industries,
        jobs,
    };
};

export default useJobList;
