import React, { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { RadioGroup } from '@fairstone/ui/core';
import { Radio } from '@fairstone/ui/core/components/Radio';
import { Typography } from '@fairstone/ui/core/components/Typography';
import { t } from '@fairstone/ui/core/utils/translate';

import { PrimaryButton } from 'components/Buttons/walmart/PrimaryButton';
import { TContactMethod } from 'store/redux/modules/user/types';
import { IAuthMethodFormData } from 'utils/forms/types';

import { IAuthResponse } from '../Continue';

import styles from './ContinueScreen.module.scss';

interface IContinueScreenProps {
    authResponse?: IAuthResponse | null;
    formOptions: UseFormReturn<IAuthMethodFormData>;
    onSubmit: (data: IAuthMethodFormData) => void;
}

export const ContinueScreen: React.FC<IContinueScreenProps> = ({
    authResponse,
    formOptions,
    onSubmit,
}): React.ReactElement => {
    const { handleSubmit, reset } = formOptions;
    const [selectedRadio, setSelectedRadio] = useState<string | null>(null);
    const { start } = useSessionManager();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedRadio(event.target.value);
        const method = event.target.value as TContactMethod;
        reset({ contactMethod: method });
    };

    useEffect(() => {
        start();
    }, []);

    return (
        <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Typography variant="h1">{t('pages.saveAndContinue.headline')}</Typography>
                    <Typography variant="body3">{t('pages.saveAndContinue.toResumeApplication')}</Typography>
                </div>
                {authResponse?.success && (
                    <RadioGroup aria-label="email-me" className={styles.radioButtonGroup} onChange={handleRadioChange}>
                        <div className={styles.radioButtonContainer}>
                            {authResponse.email && (
                                <Radio
                                    appearance="new"
                                    label={t('pages.saveAndContinue.emailMe')}
                                    size="large"
                                    value="email"
                                />
                            )}
                        </div>
                    </RadioGroup>
                )}
            </div>
            <div className={styles.button}>
                <PrimaryButton data-testid="submitButton" disabled={selectedRadio === null} type="submit">
                    {t('buttons.sendPassCode')}
                </PrimaryButton>
            </div>
        </form>
    );
};
