import React from 'react';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import styles from './Footer.module.scss';

interface IFooter {
    className?: string;
}
export const Footer: React.FC<IFooter> = ({ className }) => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className={cx(styles.footer, className)}>
            <span>{t('components.footer.walmartCopyright', { year: currentYear })}</span>
        </footer>
    );
};
