import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSessionManager } from '@fairstone/functional/core/components/SessionManager/SessionManagerProvider';
import { Loading } from '@fairstone/ui/core/components/Loading';
import { TrackJS } from '@fairstone-frontend/utils/core/logs';
import usePersistedQueryParams from 'hooks/usePersistedQueryParams/usePersistedQueryParams';

import { useAppDispatch } from 'store/redux/hooks';
import { saveInitialQueryParams } from 'store/redux/modules/router';
import { initializeUnauthStateActionAsync } from 'store/redux/modules/user';
import { baseRoute, ERoutes } from 'utils/constants';

import { WelcomeConsentScreen } from './screens/WelcomeConsentScreen';

export const WelcomeConsentPage: React.FC = (): React.ReactElement => {
    const navigate = useNavigate();
    const { start } = useSessionManager();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const locale = intl.locale.split('-')[0];
    const qp = usePersistedQueryParams({ mode: 'load' });
    useEffect(() => {
        dispatch(saveInitialQueryParams(qp));
    }, []);

    const onApplyNow = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(initializeUnauthStateActionAsync({ language: locale as 'en' | 'fr' }));
            if (response?.error) {
                navigate(`/${ERoutes.ERROR}`);
            } else {
                start();
                navigate(`/${baseRoute}/${ERoutes.WHERE_CAN_WE_REACH_YOU}`);
            }
        } catch (err) {
            TrackJS.track(err!);
            navigate(`/${ERoutes.ERROR}`);
        }
    }, []);

    const onCancel = () => {
        navigate('/');
    };

    return (
        <>
            {isLoading && <Loading />}
            <WelcomeConsentScreen locale={locale} onApplyNow={onApplyNow} onCancel={onCancel} />
        </>
    );
};
