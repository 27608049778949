import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Divider } from '@fairstone/ui/core';
import { Button } from '@fairstone/ui/core/components';
import { LocaleSwitcher } from '@fairstone/ui/core/components/utils/LocaleSwitcher';
import { useMediaQuery } from '@fairstone/ui/core/utils/media/useMediaQuery';
import { t } from '@fairstone/ui/core/utils/translate';
import cx from 'classnames';

import { SaveAndExit } from 'components/SaveAndExit';
import { PUBLIC } from 'config/constants';
import { localesMapping } from 'locales';
import { selectApplicant, selectIsInContinueFlow } from 'store/redux/modules/user/selectors';
import { hideSaveAndContinueButton } from 'utils/url/saveExitButton';

import styles from './Header.module.scss';

interface IHeader {
    className?: string;
}

export const Header: React.FC<IHeader> = ({ className }) => {
    const { email } = useSelector(selectApplicant);
    const [openDialog, setOpenDialog] = useState(false);
    const intl = useIntl();
    const isInContinueFlow = useSelector(selectIsInContinueFlow);
    const headerRef = useRef<HTMLElement>(null);
    const location = useLocation();

    const isSaveAndExitAvailable = !!email && !isInContinueFlow && !hideSaveAndContinueButton(location.pathname || '');
    const WalmartRewardsImageEn = `${PUBLIC}/assets/walmart/logo-en.svg`;
    const WalmartRewardsImageFr = `${PUBLIC}/assets/walmart/logo-fr.svg`;

    const onClickSaveAndExit = () => setOpenDialog(true);
    const onClose = () => setOpenDialog(false);

    const isDesktop = !useMediaQuery('tab-port');

    useEffect(() => {
        const headerNode = headerRef.current;
        if (headerNode) headerNode.focus();
    }, [location]);

    return (
        <header className={cx(className, styles.header)} ref={headerRef} tabIndex={-1}>
            <div className={styles.logo}>
                {isDesktop ? (
                    <img
                        alt="Walmart Rewards"
                        src={intl.locale.includes('en') ? WalmartRewardsImageEn : WalmartRewardsImageFr}
                    />
                ) : (
                    <div>
                        <img
                            alt="Walmart Rewards"
                            height="29px"
                            src={`${PUBLIC}/assets/walmart/SparkLogo.svg`}
                            width="29px"
                        />
                    </div>
                )}
                <Box className={styles.ButtonsWrapper}>
                    <LocaleSwitcher
                        ariaLabel={intl.formatMessage({ id: 'localeSwitcher.aria-label' })}
                        className={styles.switcher}
                        label={t('localeSwitcher.label')}
                        localesMapping={localesMapping}
                    />
                    {isSaveAndExitAvailable && (
                        <>
                            <Divider className={styles.divider} flexItem orientation="vertical" variant="middle" />
                            <Button
                                appearance="text"
                                border
                                className={styles.button}
                                disableRipple
                                onClick={onClickSaveAndExit}
                                size="medium"
                            >
                                {t('components.header.saveAndExit')}
                            </Button>
                        </>
                    )}
                </Box>
                {isSaveAndExitAvailable && openDialog && <SaveAndExit onClose={onClose} />}
            </div>
        </header>
    );
};
